import React, { useState, useEffect } from 'react';
import Wever from 'wever-structure-referencement';
import { Spinner } from 'reactstrap';

import 'wever-structure-referencement/dist/styles/bootstrap.css';

function App() {
  return  <Wever />;
}

export default App;
